/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@2.1.4/dist/jquery.min.js
 * - /npm/@fancyapps/fancybox@3.3.5/dist/jquery.fancybox.min.js
 * - /npm/webfontloader@1.6.28/webfontloader.min.js
 * - /npm/scrolltofixed@1.0.6/jquery-scrolltofixed.min.js
 * - /npm/jquery-match-height@0.7.2/dist/jquery.matchHeight.min.js
 * - /npm/sticky-kit@1.1.3/dist/sticky-kit.min.js
 * - /npm/wowjs@1.1.3/dist/wow.min.js
 * - /npm/jquery-validation@1.17.0/dist/jquery.validate.min.js
 * - /npm/jquery-lazy@1.7.7/jquery.lazy.min.js
 * - /npm/jquery-lazy@1.7.7/jquery.lazy.plugins.min.js
 * - /npm/bootstrap@3.3.6/dist/js/bootstrap.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
